
html, body {
    height: 100%;
    margin: 0px;
    padding: 0px;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
}

#daily-map {
    background: #0f0;
    height: 80%
    /*height: 50%*/
}

.daily-map {
    height: 100%
}

.app-header {
    color: white;
    background-color: #282c34;
}

.app-header-top {
    font-size: 24px;
}

.app-header-date {
    font-size: 32px;
    font-style: italic;
    line-height: 45px;

    border-top-color: yellow;
    border-top-width: 1px;
    border-top-style: solid;

    color: white;
    background-color: #282c34;
}